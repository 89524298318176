import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "rawbike" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "rawbike-elcyklar---ta-stadslivet-till-nästa-nivå"
    }}>{`RAWBIKE Elcyklar - Ta Stadslivet till Nästa Nivå`}</h1>
    <p>{`Välkommen till vår sida dedikerad till de innovativa och högpresterande elcyklarna från RAWBIKE. Oavsett om du pendlar dagligen i stadsmiljö eller bara älskar att utforska nya platser på två hjul, har RAWBIKE en elcykel för dig. Genom en kombination av svensk design och avancerad teknik erbjuder RAWBIKE en rad elcyklar som tillgodoser olika behov och livsstilar. Låt oss gå igenom deras olika serier och dess unika egenskaper.`}</p>
    <h2 {...{
      "id": "rawbike-urban"
    }}>{`RAWBIKE URBAN`}</h2>
    <p>{`Upplev det optimala stadslivet med `}<strong parentName="p">{`RAWBIKE URBAN`}</strong>{`, designad specifikt för urban pendling. Med en energieffektiv 250W motor och pedalassistans kan du snabbt nå en hastighet på 25 km/h. Funktioner som knus-sadel och robusta 26-tums aluminiumfälgar gör färden både bekväm och stabil. Den stilrena designen finns i matte black, ivory white och sand beige och är utrustad med smarta funktioner som integrerade ljus och en LCD-skärm som ger dig all nödvändig information. Med upp till 60 km räckvidd och full laddning på bara 5-6 timmar, är `}<strong parentName="p">{`RAWBIKE URBAN`}</strong>{` redo för alla dina stadssäventyr.`}</p>
    <h2 {...{
      "id": "rawbike-250e---hopfällbar-elcykel"
    }}>{`RAWBIKE 250E - Hopfällbar Elcykel`}</h2>
    <p>{`För den som söker flexibilitet och enkel transport är `}<strong parentName="p">{`RAWBIKE 250E`}</strong>{` den perfekta lösningen. Denna hopfällbara elcykel är utrustad med en kraftfull 250W motor och ett 48V/16Ah batteri som ger en räckvidd på upp till 50 km per laddning. Med sin tekniskt avancerade GPS-teknik kan du alltid hålla koll på din position och skyddas mot stöld. Den hopfällbara ramen gör det enkelt att transportera och förvara cykeln, vilket är perfekt för pendling med olika transportmedel. Robusta, punkteringsskyddade däck anpassade för nordiska förhållanden gör att `}<strong parentName="p">{`RAWBIKE 250E`}</strong>{` kan användas året runt.`}</p>
    <h2 {...{
      "id": "rawbike-city"
    }}>{`RAWBIKE CITY`}</h2>
    <p>{`För den som vill kombinera stil och funktionalitet är `}<strong parentName="p">{`RAWBIKE CITY`}</strong>{` det perfekta valet. Denna eleganta elcykel, designad i Sverige, erbjuder en tyst men kraftfull 250W-motor som är optimerad för stadstrafik. Med AVS-systemet från Atran Velo kan du enkelt anpassa cykeln med tillbehör som korgar och barnstolar. RAWBIKE CITY har 20-tums hjul och en toppfart på 25 km/h, med en räckvidd på upp till 50 km. Med en stilren matte black finish kombinerar `}<strong parentName="p">{`RAWBIKE CITY`}</strong>{` stil, flexibilitet och kraft, vilket gör den idealisk för både den dagliga pendlingen och nöjesturer runt staden.`}</p>
    <h2 {...{
      "id": "köpguiden-vilken-rawbike-elcykel-passar-dig"
    }}>{`Köpguiden: Vilken RAWBIKE Elcykel Passar Dig?`}</h2>
    <p>{`Att välja rätt elcykel kan vara en utmaning, men här är några tips för att underlätta ditt val:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Stadspendling:`}</strong>{` Om du pendlar dagligen inom staden och värdesätter bekvämlighet och stil, är `}<strong parentName="p">{`RAWBIKE URBAN`}</strong>{` och `}<strong parentName="p">{`RAWBIKE CITY`}</strong>{` båda utmärkta val. URBAN erbjuder hög komfort och en längre räckvidd, medan CITY ger dig flexibilitet med sitt AVS-system och en kompakt design.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Flexibilitet och Förvaring:`}</strong>{` Om du behöver en cykel som är lätt att transportera och förvara, är `}<strong parentName="p">{`RAWBIKE 250E`}</strong>{` den perfekta lösningen med sin hopfällbara ram och avancerade GPS-säkerhet.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Året-Runt Användning:`}</strong>{` För dig som planerar att använda elcykeln året runt och vill ha robusta däck som klarar nordiska förhållanden, är `}<strong parentName="p">{`RAWBIKE 250E`}</strong>{` det bästa valet tack vare sina punkteringsskyddade däck.`}</p>
      </li>
    </ol>
    <p>{`Genom att välja en elcykel från RAWBIKE investerar du i både miljön och din egen komfort. Oavsett vilken serie du väljer kan du vara säker på att du får en högkvalitativ och pålitlig elcykel som förbättrar din dagliga pendling och äventyr i stadsmiljön.`}</p>
    <p>{`Köp din RAWBIKE elcykel idag och upplev skillnaden!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      